import React, {useContext, useState} from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack
} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useNavigate, useSearchParams} from "react-router-dom";
import UserContext from "../../context/user/userContext";
import * as PropTypes from "prop-types";
import StarIcon from '@mui/icons-material/Star';
import LinearProgress from "@mui/material/LinearProgress";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {styled} from "@mui/material/styles";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';


const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom:0;
  }
`);

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        minWidth:{xs:"80%",md:"50%",xl:"30%",lg:"30%"},
        width:"100%"
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiBox-root':{
        marginBottom:"5%"
    }
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseOutlinedIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

StarIcon.propTypes = {children: PropTypes.node};


const CityCard = (props) => {
    const {cities} = props;



    const [expandedId, setExpandedId] = React.useState(-1);

    const [ratingOpen, setRatingOpen] = useState(false);

    const navigateToRating = (city) => {
        navigate(`/vote/${city.cityId}/?uuid=${searchParams.get('uuid')}`, {state: {"city": city}})
    }

    const userContext = useContext(UserContext)
    const {userMeta, setUserCityStartedAt, loadingUserVotings} = userContext

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleExpandClick = i => {
        setExpandedId(expandedId === i ? -1 : i);
    };

    const openPdf = city =>{
        window.open(city.pdfSource, '_blank');
    }

    const handleRatingClose = () => {
        setRatingOpen(false);
    }

    const handleRatingOpen = () => {
        setRatingOpen(true)
    }

    const navigateCity = (city) => {
        const cityProgressKey = `city${city.cityId}_progress`;
        let cityStep = 1;
        if (userMeta[cityProgressKey]) cityStep = userMeta[cityProgressKey]
        const cityStartedAtKey = "city" + city.cityId + '_startedAt';
        if (userMeta[cityStartedAtKey] == null) {
            console.log(userMeta[cityStartedAtKey])
            setUserCityStartedAt(city.cityId)
        }
        navigate(`/city-detail/${city.cityId}/${cityStep}?uuid=${searchParams.get('uuid')}`, {
            state: {
                city: city,
            }
        });

    }

    const startCityAgain = (city) => {
        navigate(`/city-detail/${city.cityId}/1?uuid=${searchParams.get('uuid')}`, {
            state: {
                city: city,
            }
        });
    }

    return (

        (loadingUserVotings) ? <CircularProgress></CircularProgress> :
            <>
                {cities.map((city, i) => {
                    return (
                        <Grid item xs={12} key={i} className={"ecod-city-card"}>
                            <Card sx={{
                                borderRadius: "9px",
                                border: "0.5pt solid #E6E6E6",
                                display: "flex",
                                flexDirection: "column",
                                minHeight:{lg:"100px"},
                                backgroundColor: "#F9F9F9"
                            }} elevation={2}
                                  aria-expanded={expandedId === i}
                                  aria-label="show more">
                                <Box sx={{display: 'flex', flexDirection: 'row'}} onClick={() => handleExpandClick(i)}>
                                    <Grid container sx={{minHeight:{lg:"11vh"}}} justifyContent={"center"}>
                                        <Grid item xs={3} md={3} display={"flex"} padding={"1.5%"}>
                                            <Box sx={{position: 'relative'}}>
                                                {/*  <CardMedia
                                                        component="img"
                                                        image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                                                    />*/}
                                                <CardMedia
                                                    component="img"
                                                    sx={{height: {xs: "auto", lg: "100%"}, width: {xs: "100%", lg: "auto"}, borderRadius: "9pt", display: "flex", maxHeight:{lg:"100px",xl:"100px"}}}
                                                    image={`${city.cityImage}`}
                                                    alt="altText"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={7} md={7} padding={"0"} height={"20%"}>
                                            <CardContentNoPadding sx={{
                                                textAlign: "left",
                                                padding: 0,
                                                paddingLeft: 1,
                                                paddingBottom: "15px",
                                                display: "flex",
                                                flexDirection: "column",
                                                marginLeft:"3px",
                                                marginTop: "3px"


                                            }}
                                        >
                                                <Typography variant={"h6"}>{city.cityName}</Typography>
                                                <Typography className={"ecod-subtext-scaling"} variant={"subtitle2"}>{city.shortDesc}</Typography>
                                            </CardContentNoPadding>
                                        </Grid>
                                        <Grid item xs={2} md={2} padding={"0"}>
                                            <CardContent sx={{flex: '1 0 auto', textAlign: "left"}}>
                                                {city.userVoting &&
                                                    <Stack direction="row" alignItems="center">
                                                        <StarRoundedIcon fontSize={"0.8rem"}></StarRoundedIcon>
                                                        <Typography fontSize={"0.8rem"} lineHeight={1}
                                                                    padding={"0"}>{city.userVoting.rating}</Typography>
                                                    </Stack>
                                                }
                                                {city.userVoting == null && city.userProgress && city.uesrCountry !== city.countryCode &&
                                                    <LinearProgress color="accent" variant="determinate"
                                                                    value={Math.round(
                                                                        city.userProgress / city.pages.length * 100
                                                                    )}/>
                                                }
                                                {city.uesrCountry == city.countryCode && city.userVoting == null &&
                                                    <StarBorderRoundedIcon className={"line-through-icon"}
                                                                           fontSize={"small"}
                                                                           color={"accent"}
                                                    ></StarBorderRoundedIcon>
                                                }
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column'}} >
                                    <Collapse in={expandedId === i} timeout="auto" unmountOnExit sx={{minHeight:"100px"}}>

                                        <CardContent sx={{ p:0, '&:last-child': { pb: 1, pt: 1 }}}>
                                            <Grid container alignItems={"center"} justifyContent={"space-evenly"}>
                                                <Grid item xs={4} display={"flex"} alignItems={"center"} justifyContent={"space-around"}>
                                                    <Button
                                                            fullWidth={false} 
                                                            sx={{borderRadius: "50px"}}
                                                            size={"small"} 
                                                            onClick={() => openPdf(city)} 
                                                            startIcon={<VisibilityOutlinedIcon size={"small"} color={"secondary"}/>}
                                                    >
                                                        <Typography  variant={"body2"}>PDF</Typography>
                                                    </Button>
                                                </Grid>
                                                {city.userProgress == city.pages.length && city.uesrCountry != city.countryCode && !city.finishedVote &&

                                                    <Grid item  xs={4} display={"flex"} alignItems={"center"}
                                                          sx={{justifyContent:{lg:"center"}}}
                                                          justifyContent={"space-between"}>
                                                        <Button
                                                                fullWidth={false} 
                                                                sx={{borderRadius: "50px"}} 
                                                                size={"small"} 
                                                                onClick={() => navigateToRating(city)} 
                                                                startIcon={<StarBorderRoundedIcon size={"small"} color={"secondary"}/>}
                                                        >
                                                            <Typography  variant={"body2"}>

                                                                {city.userVoting ? "Edit Rating " : "Rate now"}
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                }

                                                {city.uesrCountry == city.countryCode && city.userVoting == null &&
                                                    <Grid item xs={4} display={"flex"} alignItems={"center"}
                                                          sx={{justifyContent:{lg:"center"}}}
                                                          justifyContent={"space-between"} onClick={handleRatingOpen}>
                                                        <Button fullWidth={false} 
                                                                sx={{borderRadius: "50px"}} 
                                                                size={"small"} 
                                                                onClick={handleRatingOpen} 
                                                                startIcon={<StarBorderRoundedIcon className={"line-through-icon"} size={"small"} color={"accent"}/>}
                                                        >
                                                            <Typography mt={0.1} ml={0.2} sx={{color: '#929292'}} variant={"body2"}>
                                                                Rate now
                                                            </Typography>
                                                        </Button>
                                                    </Grid>

                                                }
                                                <Grid item xs={4} >
                                                    {city.userVoting == null && !userMeta.finished_voting &&
                                                        <Button fullWidth={false} 
                                                                sx={{borderRadius: "50px"}}
                                                                size={"small"}
                                                                variant={"contained"}
                                                                onClick={() => navigateCity(city)}
                                                                color={"secondary"} 
                                                                startIcon={<ArrowForwardIcon/>}
                                                        >
                                                            {city.userProgress ? "Continue" : "Start"}
                                                        </Button>
                                                    }
                                                    {city.userProgress == city.pages.length && city.userVoting && !userMeta.finished_voting &&
                                                        <Button fullWidth={false} 
                                                                sx={{borderRadius: "50px"}}
                                                                size={"small"}
                                                                variant={"contained"}
                                                                onClick={() => startCityAgain(city)}
                                                                color={"secondary"} 
                                                                startIcon={<ArrowForwardIcon/>}
                                                        > 
                                                            Again
                                                        </Button>
                                                    }
                                                    {userMeta.finished_voting &&
                                                        <Button fullWidth={false} 
                                                                sx={{borderRadius: "50px"}}
                                                                size={"small"}
                                                                variant={"contained"}
                                                                onClick={() => startCityAgain(city)}
                                                                color={"secondary"} 
                                                                startIcon={<ArrowForwardIcon/>}
                                                        > 
                                                            View Only
                                                        </Button>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Collapse>
                                </Box>
                            </Card>
                        </Grid>
                    )
                })}
                <BootstrapDialog
                    onClose={handleRatingClose}
                    aria-labelledby="customized-dialog-title"
                    open={ratingOpen}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleRatingClose}>
                    </BootstrapDialogTitle>

                    <Box width={"80%"}>
                    <DialogContent sx={{textAlign: "center"}}>
                        <Typography variant={"h5"} mb={4}> Please note:  </Typography>
                        <Typography variatnt={"body2"} gutterBottom>
                            You can only rate cities outside your country of residence.
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{display: "flex", width: "100%", alignItems: "center", padding: "10",mb:"10px"}} >
                        <Button variant={"contained"} fullWidth={true} className={"button-primary"}
                                onClick={handleRatingClose}>
                            Close
                        </Button>
                    </DialogActions>
                    </Box>
                </BootstrapDialog>
            </>
    )
};


export default CityCard;