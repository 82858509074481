import React, {useCallback, useReducer} from "react";
import UserContext from "./userContext";
import userReducer from "./userReducer";
import axios from "axios";
import {useSearchParams} from "react-router-dom";
import setAuthHeader from "../../utils/setAuthHeader"
import {
    CITY_OVERALL_UPDATED,
    CITY_STARTED_AT_UPDATED,
    CITY_STEP_UPDATED, GET_CITY_USERMETA_CONTEXT,
    GET_USER_META, GET_USER_VOTINGS, SET_CURRENT_AUDIO_FILE, UPDATE_FINAL_RATING,
    USER_AUTH_ERROR, USER_FINISHED_TUTORIAL,
} from "../types";
import cityJson from "../../res/cities.js";
import tutorialJson from "../../res/tutorial";


const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

const UserState = props => {
    const initialState = {
        isAuthenticated: null,
        loading: true,
        userMeta: [],
        userVotings:[],
        error: null,
        cities: cityJson,
        loadingUserVotings: true,
        currentAudioSrc: null,
        cityUserMetaContext: [],
        tutorialPages: tutorialJson
    };

    const [state, dispatch] = useReducer(userReducer, initialState)
    const [searchParams] = useSearchParams();

    const loadUserMeta = useCallback(async () => {
        setAuthHeader(searchParams.get('uuid'));
        try {
            const res = await axios.get(API_URL+'/api/get-user-meta.php')
            dispatch({
                type: GET_USER_META,
                payload: res.data
            });
            await getUserCityContext(res.data)

        } catch (err) {
            console.log(err);
            dispatch({
                type: USER_AUTH_ERROR,
            });
        }


    }, [dispatch]);

    const loadUserVotings = useCallback(async () => {
     /*   setAuthHeader(searchParams.get('uuid'));
        state.loadingUserVotings = true;
        try {
            const res = await axios.get('/api/get-user-votings.php')
            dispatch({
                type: GET_USER_VOTINGS,
                payload: res.data
            });


        } catch (err) {
            dispatch({
                type: USER_AUTH_ERROR,
            });
        }*/
    }, [dispatch]);


    const setUserStep = useCallback( async(city,step) =>{
        setAuthHeader(searchParams.get('uuid'))
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try{
            const payload = {
                city: city,
                step: step,
            }
            const res = await axios.post(API_URL+'/api/set-user-city-progress.php',payload,config)
            dispatch({
                type: CITY_STEP_UPDATED,
                city: res.data.city,
                step: res.data.step
            });

        }catch (err){
            console.error(err);
        }
    },[dispatch])

    const setUserOverallStep = useCallback( async(city,step) =>{
        setAuthHeader(searchParams.get('uuid'))
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try{
            const payload = {
                city: city,
                step: step,
            }
            const res = await axios.post(API_URL+'/api/set-user-overall-progress.php',payload,config)
            dispatch({
                type: CITY_OVERALL_UPDATED,
                cityCol: "overall_progress_city",
                stepCol: "overall_progress_step",
                city: res.data.city,
                step: res.data.step
            });

        }catch (err){
            console.error(err);
        }
    },[dispatch])


    const setUserCityStartedAt = useCallback( async(city) =>{
        setAuthHeader(searchParams.get('uuid'))
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try{
            const payload = {
                city: city,
            }
            const res = await axios.post(API_URL+'/api/set-user-city-startedAt.php',payload,config)
            dispatch({
                type: CITY_STARTED_AT_UPDATED,
                city: res.data.city,
                date: res.data.date
            });

        }catch (err){
            console.error(err);
        }
    },[dispatch])


    const setUserAction = useCallback(async (action) => {
        setAuthHeader(searchParams.get('uuid'));
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const userAction = {
            action: action
        }
        try {
            const res = await axios.post(API_URL+'/api/set-user-timestamps.php',userAction,config)
            if(action ==="has_started" && res){
                dispatch({
                    type: USER_FINISHED_TUTORIAL,
                    payload: true
                });

            }
        } catch (err) {
        }
    }, [dispatch]);


    const setUserVoting = useCallback(async (city,rating,additionalFeedback ="") => {
        setAuthHeader(searchParams.get('uuid'));

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const req = {
            city,
            rating,
            additionalFeedback
        }
        try {
            await axios.post(API_URL+'/api/set-user-rating.php',req,config)
        } catch (err) {
            console.log(err);
        }
    }, [dispatch]);


    const updateUserPageTime = useCallback(async () => {

        setAuthHeader(searchParams.get('uuid'));
        try {
            const res = await axios.post(API_URL+'/api/update-user-page-time.php')
        } catch (err) {
            console.log(err);
        }


    },[])
    const setFinalUserVoting = useCallback(async () => {
        setAuthHeader(searchParams.get('uuid'));


        try {
            const res = await axios.post(API_URL+'/api/set-user-final-voting.php')
            if(res){
                dispatch({
                    type: UPDATE_FINAL_RATING,
                    payload: true
                });
            }
        } catch (err) {
            console.log(err);
        }
    }, [dispatch]);


    const setCurrentAudioSrc = useCallback(async (data)=>{

        dispatch({
            type: SET_CURRENT_AUDIO_FILE,
            payload: data
        })
    })

    const getUserCityContext = useCallback(async (userMeta)=>{
        const data = []
        cityJson.cities.sort(() => Math.random() - 0.5)

        try {
            const res = await axios.get(API_URL+'/api/get-user-votings.php')
            dispatch({
                type: GET_USER_VOTINGS,
                payload: res.data
            });
            cityJson.cities.forEach((el) =>{
                const cityProg ="city"+ el.cityId +"_progress"
                const cityVoted = "city"+ el.cityId +"_votedAt"
                const userVoting = res.data.find(item => item.city === el.cityId)


                el['userProgress'] = userMeta[cityProg]
                el['userVotedAt'] = userMeta[cityVoted]
                el['uesrCountry'] = userMeta['country']
                el['finishedVote'] = userMeta['finished_voting']
                if(userVoting === undefined){
                    el['userVoting'] = null

                }else{
                    el['userVoting'] = userVoting

                }
                data.push(el)
            })



            dispatch({
                type: GET_CITY_USERMETA_CONTEXT,
                payload: data
            })

        } catch (err) {
            dispatch({
                type: USER_AUTH_ERROR,
            });
        }


    })



    return (
        <UserContext.Provider
            value={{
                isAuthenticated: state.isAuthenticated,
                userMeta: state.userMeta,
                loading: state.loading,
                error: state.error,
                cities: state.cities.cities,
                userVotings: state.userVotings,
                loadingUserVotings: state.loadingUserVotings,
                currentAudioSrc:state.currentAudioSrc,
                cityUserMetaContext:state.cityUserMetaContext,
                tutorialPages:state.tutorialPages.pages,
                loadUserMeta,
                getUserCityContext,
                setUserAction,
                setUserStep,
                setUserCityStartedAt,
                setUserOverallStep,
                setUserVoting,
                updateUserPageTime,
                loadUserVotings,
                setFinalUserVoting,
                setCurrentAudioSrc
            }}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserState