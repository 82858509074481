export const GET_USER_META = 'GET_USER_META'
export const USER_AUTH_ERROR = 'USER_AUTH_ERROR';
export const GET_CITIES = "GET_CITIES";
export const CITY_STEP_UPDATED = "CITY_STEP_UPDATED";
export const CITY_STARTED_AT_UPDATED = "CITY_STARTED_AT_UPDATED"
export const CITY_OVERALL_UPDATED ="CITY_OVERALL_UPDATED"
export const GET_USER_VOTINGS = "GET_USER_VOTINGS";
export const UPDATE_FINAL_RATING = "UPDATE_FINAL_RATING"
export const SET_CURRENT_AUDIO_FILE = "SET_CURRENT_AUDIO_FILE"
export const GET_CITY_USERMETA_CONTEXT ="GET_CITY_USERMETA_CONTEXT"
export const USER_FINISHED_TUTORIAL = "USER_FINISHED_TUTORIAL"
export const DEADLINE_VOTING = new Date(2025, 3, 12, 23, 59, 59);
