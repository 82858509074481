import React, {useContext} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import UserContext from "../../context/user/userContext";
import Page from "../Page";
import {Box, Button, BottomNavigation, Grid, ThemeProvider, Typography} from "@mui/material";
import { createTheme } from '@mui/material/styles';
import Layout from "../Layout";
import AdjustingButtonBox from "./pagedetails/AdjustingButtonBox";
import AdjustingButtonGrid from "./pagedetails/AdjustingButtonGrid";
import ContentBox from "./pagedetails/ContentBox";


let firstPageTheme = createTheme({
    palette: {
        primary: {
            main: '#009FE3',
        }
    },
    typography:{
        // fontFamily:[
        //     'Segoe UI'
        // ],
        fontFamily: [
            'Noto Sans'
        ],
        button:{
            textTransform: "none"
        },
    },
    overrides :{
        MuiButton:{
                background:'#009FE3',
                borderRadius: 20,
                color: "white"
        },
    }
});


const Tutorial = () => {
    const userContext = useContext(UserContext);
    const {tutorialPages, setUserAction, setCurrentAudioSrc,loadUserMeta} = userContext;

    const params = useParams();

    const currentPage = tutorialPages.find(page => page.tutorialId == params.tutorialStep);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const finishStep = () => {
        setUserAction("has_started");
        loadUserMeta();
        navigate(`/?uuid=${searchParams.get('uuid')}`,{state: {"finishedIntro": true}})
    }


    const nextStep = () => {
        const nextPage = currentPage.nextId
        const nextStep = tutorialPages.find(page => page.tutorialId == nextPage);

        if (nextStep) {
            setCurrentAudioSrc(nextStep.audioSrc);
        }

        navigate(`/tutorial/${nextPage}/?uuid=${searchParams.get('uuid')}`);
    }

    const prevStep = () => {
        const nextPage = currentPage.prevId
        const nextStep = tutorialPages.find(page => page.tutorialId == nextPage);

        if (nextStep) {
            setCurrentAudioSrc(nextStep.audioSrc)
        }

        navigate(`/tutorial/${nextPage}/?uuid=${searchParams.get('uuid')}`);
    }

    if(currentPage.tutorialId == 1) {
        return(
            <ThemeProvider theme={firstPageTheme}>
                <div style={{maxHeight:"100%"}}>
                <div style={{
                    height:"100vh",
                    width:"100vw",
                    overflow:"hidden",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    backgroundImage: `url(${currentPage.backgroundImageSource})`,
                    backgroundColor:'#001F3D',
                    backgroundSize: 'cover', 
                    backgroundRepeat: 'no-repeat',
                    position:"absolute",
                    backgroundPosition: "center"
                }}
                     className="ecod-starting-page"
                >  </div>
                    {/*<Grid container alignItems={"center"} justifyContent={"center"}
                          sx={{paddingTop:{xs:"75vmax",sm:"75vmax", md:"80vh",lg:"80vh"}}}
                          paddingLeft={"8%"} paddingRight={"8%"}>
                        <AdjustingButtonBox>
                            <Button 
                                className="button-primary" 
                                color={"primary"}
                                fullWidth={true} 
                                variant={"contained"}
                                onClick={nextStep}
                            >
                                Start
                            </Button>
                        </AdjustingButtonBox>
            </Grid>*/}
                </div>
                <BottomNavigation sx={{position:"fixed",bottom:"0px",paddingBottom:"12%",width:"100%", height:"auto", backgroundColor: "transparent"}}>
                    <AdjustingButtonGrid>
                        <AdjustingButtonBox>
                            <Button 
                                className="button-primary" 
                                color={"primary"} 
                                fullWidth={true} 
                                variant={"contained"}
                                onClick={nextStep}
                            >
                                Next
                            </Button>
                        </AdjustingButtonBox>
                    </AdjustingButtonGrid>
                </BottomNavigation>
            </ThemeProvider>
        )
    }
    else {
        return(
            <Layout audioSrc={currentPage.audioSrc} totalSteps={tutorialPages.length} currentPosition={parseInt(currentPage.tutorialId)}>
                <>
                {/*<ProgressBar total={tutorialPages.length} position={currentPage.tutorialId}></ProgressBar>*/}
                    <Page>
                        <ContentBox>
                            <Grid item xs={12} paddingTop={{xs: "8%", lg: 0}} paddingBottom={{xs: "8%", lg: 0}}>
                                <Box className={"ecod-city-detail-wrapper ecod-tutorial"}>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        Dear Citizen Juror, <br/>
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        Welcome to the selection of the European Capital of Democracy 2026!
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        You will see the three finalists in random order. Please note that you can see, but not rate candidate cities in your country of residence (as selected by you during registration).
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        Rating takes place after you have viewed the mayor’s video, the summary, the democracy projects and project ideas of each individual city.
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        You can rate cities outside your country of residence with 1 to 5 stars (1 star = lowest, 5 stars = highest ranking).
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        For your lowest (1 star) and highest (5 stars) rankings, you can optionally select the most relevant factors by clicking on them.
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        When you have viewed and rated all cities, you can easily adjust your ratings in an overview.
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        You can leave and return to this selection tool at any time before the deadline. Please make sure to complete your ratings before 12 March 2025, 23:59pm CET.
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        Please note that only completed ratings will be considered for the result.
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        The cities’ original application documents are provided as an optional source of information, for reasons of transparency and more detailed descriptions simply click on the „PDF“ icon to view them.
                                    </Typography>
                                    <Typography variant={"body1"} className={"ecod-city-detail-text"} align={"left"} gutterBottom={true}>
                                        Happy rating, <br/>
                                        your ECoD team
                                    </Typography>
                                </Box>
                            </Grid>
                        </ContentBox>
                        </Page>
                        {/*currentPage.imageSource && 
                            <ContentBox display={"flex"}>
                                <Grid item xs={12}>
                                    <img 
                                        src={`${currentPage.imageSource}`} 
                                        alt={currentPage.imageSource}
                                        width={"100%"}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12} md={12}>
                                    <Box className={"ecod-city-detail-wrapper"}>
                                    {currentPage.header && 
                                        <Typography variant={"h2"} align={"left"} gutterBottom={true}>
                                            {currentPage.header}
                                        </Typography>
                                    }

                                    {currentPage.text &&
                                        <Typography variant={"body1"} align={"left"}>
                                            {currentPage.text}
                                        </Typography>
                                    }
                                    {parse(currentPage.header)}
                                    {parse(currentPage.text)}
                                    </Box>
                                </Grid>
                            </ContentBox>
                        /*}

                        {/*!currentPage.imageSource &&
                            <ContentBox>
                                <Grid item xs={12} lg={12} md={12}>
                                    <Box className={"ecod-city-detail-wrapper"}>
                                    {currentPage.header && 
                                        <Typography variant={"h2"} className={"ecod-city-detail-wrapper"} align={"left"} gutterBottom={true}>
                                            {currentPage.header}
                                        </Typography>
                                    }

                                    {currentPage.text &&
                                        <Typography variant={"body1"} className={"ecod-city-detail-wrapper"} align={"left"}>
                                            {currentPage.text}
                                        </Typography>
                                    }
                                    {parse(currentPage.header)}
                                    {parse(currentPage.text)}
                                    </Box>
                                </Grid>
                            </ContentBox>
                        */}

                        <BottomNavigation sx={{position:"fixed",bottom:"0px",paddingBottom:"2%",width:"100%", height:"auto"}}>
                            <AdjustingButtonGrid>
                                <AdjustingButtonBox>
                                        <Button 
                                            className="button-secondary" 
                                            color={"primary"} 
                                            fullWidth={true} 
                                            variant={"outlined"}
                                            onClick={prevStep}
                                        >
                                            Back
                                    </Button>
                                </AdjustingButtonBox>

                                <AdjustingButtonBox>
                                    <Button 
                                        className="button-primary" 
                                        color={"primary"} 
                                        fullWidth={true} 
                                        variant={"contained"}
                                        onClick={finishStep}
                                    >
                                        Next

                                    </Button>
                                </AdjustingButtonBox>
                            </AdjustingButtonGrid>
                        </BottomNavigation>
                </>
            </Layout>
        )
    }
}


export default Tutorial;