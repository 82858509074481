const cityJson = {
  "cities": [
    {
      "cityId": 1,
      "cityName": "Cascais",
      "cityImage": "/assets/city1/25ECoD_cascais_logo.jpg",
      "shortDesc": "Building a Resilient Future Together",
      "countryCode": "PRT", // imporant! all capital letters
      "pdfSource": "https://capitalofdemocracy.eu/ecod-application-cascais/",
      "abbr": "CAS",
      "pages": [
        {
          "id": 1,
          "nextId": 2,
          "heading": "<h1>Cascais - Facts (1/2)</h1>",
          "content": "<p class='ecod-city-detail-text'><ul><li>Region: Lisbon District</li><li>Country: Portugal</li><li>Population: 214,158</li><li>Official language: Portuguese</li><li>Website: <a target='_blank' href='https://cascais.pt'>https://cascais.pt</a></li></ul></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 2,
          "nextId": 3,
          "prevId": 1,
          "heading": "<h1>Cascais - Facts (2/2)</h1>",
          "content": "<img class='ecod-city-image' src='/assets/city1/25ECoD_cascais_map1.jpg'> </img><img class='ecod-city-image' src='/assets/city1/25ECoD_cascais_map2.jpg'> </img>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 3,
          "nextId": 4,
          "prevId": 2,
          "heading": "<h1>Cascais - Video</h1><h2>Carlos Carreiras (Partido Social Democrata - PPD/PSD), Mayor of Cascais</h2>",
          "content": "",
          "audioSrc": "",
          "videoSrc": "/assets/city1/ECoD25_cascais_video.mp4"
        },
        {
          "id": 4,
          "nextId": 5,
          "prevId": 3,
          "heading": "<h1>Cascais - Summary (1/2)</h1><h2>Building a Resilient Future Together</h2>",
          "content": "<p class='ecod-city-detail-text' >Cascais is dedicated to fostering a resilient, inclusive, and sustainable community. By empowering citizens to actively shape their city through participatory democracy and innovative health and social systems, Cascais aims to create an environment where everyone can thrive. The city's commitment to environmental protection and intergenerational collaboration further strengthens its vision for a resilient future.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 5,
          "nextId": 6,
          "prevId": 4,
          "heading": "<h1>Cascais - Summary (2/2)</h1><h2>Building a Resilient Future Together</h2>",
          "content": "<p class='ecod-city-detail-text' >Cascais recognises the importance of addressing climate change, social inequality, housing affordability, and intergenerational collaboration to build a truly resilient democracy. The city aims to tackle these challenges by fostering community-led environmental responsibility, expanding support for vulnerable groups, ensuring affordable housing, and promoting intergenerational programmes that bridge age divides and encourage shared responsibility.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 6,
          "nextId": 7,
          "prevId": 5,
          "heading": "<h1>Cascais - Project 1</h1><h2>Tutores de Cascais</h2>",
          "content": "<p class='ecod-city-detail-text' >Empowers citizens to monitor and improve their neighbourhoods, fostering community-led urban management and environmental care.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 7,
          "nextId": 8,
          "prevId": 6,
          "heading": "<h1>Cascais - Project 2</h1><h2>Vida Cascais Platform</h2>",
          "content": "<p class='ecod-city-detail-text' >Leverages digital tools to provide accessible health and social services, promoting well-being and social equity.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 8,
          "nextId": 9,
          "prevId": 7,
          "heading": "<h1>Cascais - Project 3</h1><h2>Terras de Cascais</h2>",
          "content": "<p class='ecod-city-detail-text' >Transforms urban spaces into sustainable farms, promoting local food production, environmental awareness, and social bonds.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 9,
          "nextId": 10,
          "prevId": 8,
          "heading": "<h1>Cascais - Project 4</h1><h2>AdaptCascais Fund<h2>",
          "content": "<p class='ecod-city-detail-text' >Supports local projects that address climate change adaptation, fostering community-driven climate action and resilience.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 10,
          "nextId": 11,
          "prevId": 9,
          "heading": "<h1>Cascais - Project 5</h1><h2>Estoril Conferences</h2>",
          "content": "<p class='ecod-city-detail-text' >A global platform for dialogue on pressing global issues, inspiring local solutions and active citizenship.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 11,
          "nextId": 12,
          "prevId": 10,
          "heading": "<h1>Cascais - Project Idea A</h1><h2>GeraSol Program</h2>",
          "content": "<p class='ecod-city-detail-text' >Combats social isolation among the elderly by connecting them with young volunteers for companionship and cultural engagement.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 12,
          "nextId": 13,
          "prevId": 11,
          "heading": "<h1>Cascais - Project Idea B</h1><h2>Cascais Carbon Roadmap 2050</h2>",
          "content": "<p class='ecod-city-detail-text' >A collaborative pathway to carbon neutrality, involving citizens, businesses, and organisations in reducing emissions and adopting sustainable practices.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 13,
		  "nextId": 14,
          "prevId": 12,
          "heading": "<h1>Cascais - Project Idea C</h1><h2>Action! Citizen Engagement for a Sustainable Future</h2>",
          "content": "<p class='ecod-city-detail-text' >Empowers citizens to actively contribute to the city's sustainability goals, aligned with the United Nations Sustainable Development Goals.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 14,
          "nextId": 15,
          "prevId": 13,
          "heading": "<h1>Cascais - Project Idea D</h1><h2>Green Support Fund for Families</h2>",
          "content": "<p class='ecod-city-detail-text' >Designed to financially support families in adopting sustainable practices and infrastructure in their homes, such as solar panels, energy efficiency improvements, and waste reduction. Contributes to the local ecological transition.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 15,
          "prevId": 14,
          "heading": "<h1>Cascais - Project Idea E</h1><h2>Grandparental Leave</h2>",
          "content": "<p class='ecod-city-detail-text' >Aimed at recognizing and valuing the role of grandparents in family support, allowing them to take leave to care for their grandchildren, promoting intergenerational well-being. Enhances family cohesion and intergenerational support.</p>",
          "audioSrc": "",
          "videoSrc": ""
        }
      ]
    },
    {
      "cityId": 2,
      "cityName": "Rotterdam",
      "cityImage": "/assets/city2/25ECoD_rotterdam_logo.jpg",
      "shortDesc": "We do Democracy: democratic diversity in a city of doers",
      "countryCode": "NLD", // imporant! all capital letters
      "pdfSource": "https://capitalofdemocracy.eu/ecod-application-rotterdam/",
      "abbr": "ROT",
      "pages": [
        {
          "id": 1,
          "nextId": 2,
          "heading": "<h1>Rotterdam - Facts (1/2)</h1>",
          "content": "<p class='ecod-city-detail-text' ><ul><li>Region: South Holland</li><li>Country: Netherlands</li><li>Population: 1,750,000</li><li>Official language: Dutch</li><li>Website: <a target='_blank' href='https://www.rotterdam.nl/en'>https://www.rotterdam.nl</a></li></ul></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 2,
          "nextId": 3,
          "prevId": 1,
          "heading": "<h1>Rotterdam - Facts (2/2)</h1>",
          "content": "<img class='ecod-city-image' src='/assets/city2/25ECoD_rotterdam_map1.jpg'> </img><img class='ecod-city-image' src='/assets/city2/25ECoD_rotterdam_map2.jpg'> </img>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 3,
          "nextId": 4,
          "prevId": 2,
          "heading": "<h1>Rotterdam - Video</h1><h2>Carola Schouten (Christian Union - CU), Mayor of Rotterdam</h2>",
          "content": "",
          "audioSrc": "",
          "videoSrc": "/assets/city2/25ECoD_rotterdam_video.mov"
        },
        {
          "id": 4,
          "nextId": 5,
          "prevId": 3,
          "heading": "<h1>Rotterdam - Summary (1/2)</h1><h2>We do Democracy: democratic diversity in a city of doers</h2>",
          "content": "<p class='ecod-city-detail-text'>The overarching mission of Rotterdam's bid for the European Capital of Democracy 2026 is to enhance democratic engagement and participation among its diverse citizenry, with a particular focus on youth. The city's rich history of community initiatives and active citizenship provides a strong foundation, but faces challenges such as low voter-turnout, declining trust in government, and a perceived disconnect between citizens and formal political processes.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 5,
          "nextId": 6,
          "prevId": 4,
          "heading": "<h1>Rotterdam - Summary (2/2)</h1><h2>We do Democracy: democratic diversity in a city of doers</h2>",
          "content": "<p class='ecod-city-detail-text'>The city aims to transform these challenges into opportunities. By emphasising inclusivity, accessibility, and youth engagement, Rotterdam seeks to revitalise its democratic landscape and inspire active participation from all residents. The 2026 election year presents a unique opportunity to put democracy on the agenda, especially for young people, and to showcase Rotterdam as a model for participatory governance.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 6,
          "nextId": 7,
          "prevId": 5,
          "heading": "<h1>Rotterdam - Project 1</h1><h2>Neighborhood Hubs</h2>",
          "content": "<p class='ecod-city-detail-text' >Local offices in all 39 neighbourhoods provide accessible municipal services and support, fostering direct dialogue and participation.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 7,
          "nextId": 8,
          "prevId": 6,
          "heading": "<h1>Rotterdam - Project 2</h1><h2>Youth Hubs</h2>",
          "content": "<p class='ecod-city-detail-text' >These community centers offer safe spaces for young people aged 10-23 to develop skills, engage with their community, and have their voices heard.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 8,
          "nextId": 9,
          "prevId": 7,
          "heading": "<h1>Rotterdam - Project 3</h1><h2>Neighborhood Councils</h2>",
          "content": "<p class='ecod-city-detail-text' >These elected bodies represent the interests of residents, providing advice on local issues and promoting community involvement.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 9,
          "nextId": 10,
          "prevId": 8,
          "heading": "<h1>Rotterdam - Project 4</h><h2>City of Initiatives</h2>",
          "content": "<p class='ecod-city-detail-text' >This programme encompasses CityLab010, Right to Challenge, and Residents' Initiatives, empowering citizens to take the lead in shaping their city through innovative projects and active participation in local governance.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 10,
          "nextId": 11,
          "prevId": 9,
          "heading": "<h1>Rotterdam - Project 5</h1><h2>Citizens' Assembly on Climate</h2>",
          "content": "<p class='ecod-city-detail-text' >This participatory initiative involved a randomly selected group of residents in developing climate policy recommendations, showcasing the power of citizen engagement in addressing complex issues.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 11,
          "nextId": 12,
          "prevId": 10,
          "heading": "<h1>Rotterdam - Project Idea A</h1><h2>Democracy Daily</h2>",
          "content": "<p class='ecod-city-detail-text' >This digital platform aims to make democracy a part of everyday life by sharing stories and fostering engagement through interactive content.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 12,
          "nextId": 13,
          "prevId": 11,
          "heading": "<h1>Rotterdam - Project Idea B</h1><h2>Democracy on Wheels</h2>",
          "content": "<p class='ecod-city-detail-text' >Mobile hubs and an “Ombulance” will bring municipal services and support directly to neighbourhoods, increasing accessibility and reducing barriers to participation.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 13,
          "nextId": 14,
          "prevId": 12,
          "heading": "<h1>Rotterdam - Project Idea C</h1><h2>Travels to the Powers That Be</h2>",
          "content": "<p class='ecod-city-detail-text' >This initiative will organise trips for young people to the city's political center, providing firsthand experience and understanding of democratic processes.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 14,
          "prevId": 13,
          "heading": "<h1>Rotterdam - Project Idea D</h1><h2>Neighborhood Councils on Stage</h2>",
          "content": "<p class='ecod-city-detail-text' >This project will focus on the 2026 neighbourhood council elections, celebrating local democracy and fostering collaboration among council members.</p>",
          "audioSrc": "",
          "videoSrc": ""
        }
      ]
    },
    {
      "cityId": 3,
      "cityName": "Sofia",
      "cityImage": "/assets/city3/25ECoD_sofia_logo.png",
      "shortDesc": "Engaging, Empowering, and Evolving – A City Where Every Voice Shapes the Future",
      "countryCode": "BGR", // imporant! all capital letters
      "pdfSource": "https://capitalofdemocracy.eu/ecod-application-sofia/",
      "abbr": "SOF",
      "pages": [
        {
          "id": 1,
          "nextId": 2,
          "heading": "<h1>Sofia - Facts (1/2)</h1>",
          "content": "<p class='ecod-city-detail-text' ><ul><li>Region: Sofia</li><li>Country: Bulgaria</li><li>Population: 1,220,000</li><li>Official language: Bulgarian</li><li>Website: <a target='_blank' href='https://www.sofia.bg/en/web/sofia-municipality/'>https://sofia.bg</a></li></ul></p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 2,
          "nextId": 3,
          "prevId": 1,
          "heading": "<h1>Sofia - Facts (2/2)</h1>",
          "content": "<img class='ecod-city-image' src='/assets/city3/25ECoD_sofia_map1.jpg'> </img><img class='ecod-city-image' src='/assets/city3/25ECoD_sofia_map2.jpg'> </img>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 3,
          "nextId": 4,
          "prevId": 2,
          "heading": "<h1>Sofia - Video</h1><h2>Nikola Barbutov (We Continue the Change / Prodalzhavame promyanata - PP); Deputy Mayor</h2>",
          "content": "",
          "audioSrc": "",
          "videoSrc": "/assets/city3/25ECoD_sofia_video.mp4"
        },
        {
          "id": 4,
          "nextId": 5,
          "prevId": 3,
          "heading": "<h1>Sofia - Summary (1/2)</h1><h2>Engaging, Empowering, and Evolving - A City Where Every Voice Shapes the Future</h2>",
          "content": "<p class='ecod-city-detail-text'>The overarching mission of Sofia’s bid to become the European Capital of Democracy is to engage and empower its citizens to take an active role in shaping the city’s future. The city’s rich history of democratic reforms and citizen-centric programmes provides a strong foundation for future progress. Sofia’s democratic ambitions include adopting progressive governance practices that prioritise transparency and citizen involvement.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 5,
          "nextId": 6,
          "prevId": 4,
          "heading": "<h1>Sofia - Summary (2/2)</h1><h2>Engaging, Empowering, and Evolving – A City Where Every Voice Shapes the Future</h2>",
          "content": "<p class='ecod-city-detail-text'>The city has achieved successes in key areas such as increased transparency and open government, citizen engagement, sustainable urban development, and social innovation. Future challenges include low participation rates, the digital divide, urban sprawl, transportation challenges, social inequalities, governance and accountability, environmental sustainability, balancing development and heritage, and political polarisation.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 6,
          "nextId": 7,
          "prevId": 5,
          "heading": "<h1>Sofia - Project 1</h1><h2>Sofia Programme Europe</h2>",
          "content": "<p class='ecod-city-detail-text' >A funding programme supporting civil society organisations and democratic engagement.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 7,
          "nextId": 8,
          "prevId": 6,
          "heading": "<h1>Sofia - Project 2</h1><h2>Sofia Citizen Budget Initiative</h2>",
          "content": "<p class='ecod-city-detail-text' >A participatory budgeting programme empowering residents to propose and vote on community projects.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 8,
          "nextId": 9,
          "prevId": 7,
          "heading": "<h1>Sofia - Project 3</h1><h2>Sofia LIFE Heating Upgrade Programme</h2>",
          "content": "<p class='ecod-city-detail-text' >An initiative to improve air quality by replacing polluting heating systems with eco-friendly alternatives.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 9,
          "nextId": 10,
          "prevId": 8,
          "heading": "<h1>Sofia - Project Idea A</h1><h2>Neighbourhood Recycling Facilities Programme</h2>",
          "content": "<p class='ecod-city-detail-text' >Establishing local recycling facilities to increase accessibility and community engagement in waste management.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 10,
          "nextId": 11,
          "prevId": 9,
          "heading": "<h1>Sofia - Project Idea B</h1><h2>Sofia Sandbox for Innovation and Urban Solutions</h2>",
          "content": "<p class='ecod-city-detail-text' >Expanding an existing innovation hub to address a wider range of urban challenges.</p>",
          "audioSrc": "",
          "videoSrc": ""
        },
        {
          "id": 11,
          "prevId": 10,
          "heading": "<h1>Sofia - Project Idea C</h1><h2>The Green Ring of Sofia</h2>",
          "content": "<p class='ecod-city-detail-text' >Creating a connected, green urban pathway to reduce pollution, promote health, and enhance community interaction.</p>",
          "audioSrc": "",
          "videoSrc": ""
        }
      ]
    }
  ]
}
export default cityJson;